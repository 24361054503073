import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { ProductActions, SessionActions } from "../redux/actions";
import { v4 as uuid } from "uuid";
import { CookieActions } from "../redux/actions/cookieActions";
import { BrandConstants } from "../models";
import { logEvent, setUserProperties, trackWindowDataLayer } from "../tracker";

interface ModalContextInterface {
  showModal: boolean;
  setModal: (showModal: boolean) => void;
  lengthUnit: "cm" | "ft";
  setLengthUnit: (lengthUnit: "cm" | "ft") => void;
  weightUnit: "kg" | "lbs";
  setWeightUnit: (weightUnit: "kg" | "lbs") => void;
  productCode: string;
  sessionId: string;
  loadTool: string;
}

export const ModalContext = React.createContext<ModalContextInterface>({
  showModal: false,
  setModal: (_: boolean) => undefined,
  lengthUnit: "cm",
  setLengthUnit: (_: "cm" | "ft") => undefined,
  weightUnit: "kg",
  setWeightUnit: (_: "kg" | "lbs") => undefined,
  productCode: "",
  sessionId: "",
  loadTool: "",
});

const getLengthUnitInitialState = () => {
  const lengthUnit = localStorage.getItem("saiz-length-unit") || "cm";
  return lengthUnit as "cm" | "ft";
};
const getWeightnitInitialState = () => {
  const weightUnit = localStorage.getItem("saiz-weight-unit") || "kg";
  return weightUnit as "kg" | "lbs";
};

const ModalContextProvider = (props: any) => {
  const {
    children,
    session,
    brandCode,
    lang,
    productCode,
    auth,
    product,
    visitorId,
  } = props;
  const [showModal, setModal] = React.useState<boolean>(false);
  const [sessionProductCode, setSessionProductCode] = React.useState<string>(
    localStorage.getItem("saiz-widget-productcode") ?? productCode
  );
  const [sessionCookie, setSessionCookie] = React.useState<string>(
    localStorage.getItem("saiz-widget-cookie-flag") ?? "active"
  );
  const [sessionId] = React.useState<string>(uuid());
  const [loadSaizTool, setLoadSaizTool] = React.useState<string>(uuid());
  const { i18n } = useTranslation("common");
  const dispatch = useDispatch<any>();
  const [lengthUnit, setLengthUnit] = React.useState<"cm" | "ft">(
    getLengthUnitInitialState
  );
  const [weightUnit, setWeightUnit] = React.useState<"kg" | "lbs">(
    getWeightnitInitialState
  );

  useEffect(() => {
    function getCookie() {
      const item = localStorage.getItem("saiz-widget-cookie-flag");
      if (item) {
        setSessionCookie(item);
      }
    }
    window.addEventListener("saiz-widget-cookie", getCookie);
    return () => {
      window.removeEventListener("saiz-widget-cookie", getCookie);
    };
  }, []);

  useEffect(() => {
    dispatch(CookieActions.getCookie(props.brandCode));
  }, [props.brandCode]);

  useEffect(() => {
    localStorage.setItem("saiz-length-unit", lengthUnit);
  }, [lengthUnit]);
  useEffect(() => {
    localStorage.setItem("saiz-weight-unit", weightUnit);
  }, [weightUnit]);

  useEffect(() => {
    if (!session.isLoaded) {
      dispatch(SessionActions.setSession(sessionId));
    }
  }, []);

  useEffect(() => {
    changeLanguageHandler(lang);
  }, [lang]);

  const changeLanguageHandler = (lang: any) => {
    i18n.changeLanguage(lang?.toLowerCase());
  };
  useEffect(() => {
    function getSessionProductCode() {
      setLoadSaizTool(uuid());
      const item = localStorage.getItem("saiz-widget-productcode");
      if (item && item !== sessionProductCode) {
        setSessionProductCode(item);
      }
    }
    window.addEventListener("saiz-widget-storage", getSessionProductCode);
    return () => {
      window.removeEventListener("saiz-widget-storage", getSessionProductCode);
    };
  }, []);

  useEffect(() => {
    const eventProperties = {
      ...auth?.localStorage,
      brandCode: brandCode,
      productCode: productCode,
      session_id: sessionId,
      visitorId: visitorId,
      user_id: visitorId,
    };
    trackWindowDataLayer(
      "saiz_Recommendation_Product_Initialization",
      eventProperties
    );
    logEvent("saiz_Recommendation_Product_Initialization", "", eventProperties);
    setUserProperties(sessionId, eventProperties);
    switch (brandCode) {
      case BrandConstants.BONITA:
      case BrandConstants.MARCOPOLO:
      case BrandConstants.OHAPRIL:
      case BrandConstants.OUI:
      case BrandConstants.RICHROYALDE:
      case BrandConstants.TRIGEMA:
      case BrandConstants.TRIPLE2:
      case BrandConstants.JACKWOLFSKIN:
      case BrandConstants.ETERNA:
      case BrandConstants.SOLIVER:
      case BrandConstants.COMMA:
      case BrandConstants.SPREADSHIRT:
      case BrandConstants.SCHOEFFEL:
      case BrandConstants.BETTY:
      case BrandConstants.DRYKORN:
      case BrandConstants.TFS:
      case BrandConstants.BOGNER:
      case BrandConstants.FIREICE:
      case BrandConstants.ZERO:
        if (
          sessionCookie === "active" &&
          sessionStorage.getItem("saiz-widget-onload") === "loaded" &&
          !sessionProductCode?.includes(product?.product?.productCode)
        ) {
          dispatch(
            ProductActions.getProductDetail(
              sessionId,
              brandCode,
              lang,
              sessionProductCode,
              visitorId,
              auth
            )
          );
        }
        break;
      default:
        break;
    }
  }, [sessionProductCode, sessionCookie, loadSaizTool]);

  const input = {
    showModal,
    setModal,
    lengthUnit,
    setLengthUnit,
    weightUnit,
    setWeightUnit,
    productCode: sessionProductCode,
    sessionId,
    loadTool: loadSaizTool,
  };
  return (
    <ModalContext.Provider value={input}>{children}</ModalContext.Provider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.AuthReducer,
    session: state.SessionReducer,
    cookie: state.CookieReducer,
    product: state.ProductReducer,
  };
};

export default connect(mapStateToProps)(ModalContextProvider);
