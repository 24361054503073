import { useContext, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { AuthActions, CalculatorActions } from "./redux/actions";
import {
  DynamicSaizLogo,
  SaizImageLoader,
  SaizLink,
  SaizLoaderScreen,
  SaizModal,
  SaizNavDots,
} from "./components";
import {
  ScreenOne,
  ScreenOneBundle,
  ScreenTwo,
  ScreenThree,
  ScreenSummary,
  ScreenFour,
} from "./pages";

import { BrandConstants, ScreenTypeDto } from "./models";

import {
  BodyMeasurementRequestDto,
  BodyShapeTypeDto,
  FitPreferenceTypeDto,
  GenderTypeDto,
} from "./swagger";
import { ModalContext } from "./context/modalContext";
import EventListners from "./eventListners";
import { logEvent, trackWindowDataLayer } from "./tracker";
function App(props: any) {
  const container = document.getElementById("saiz-widget-container");
  const vId =
    container?.getAttribute("data-visitorid") ??
    container?.getAttribute("data-size-finder-variant-id");
  const { product, auth, cal, session, brandCode, lang, visitorId } = props;
  const _visitorId =
    vId ?? visitorId ?? localStorage.getItem("saiz_widget_visitorid");
  const { showModal, setModal, productCode, sessionId, loadTool } =
    useContext(ModalContext);
  const [step, setStep] = useState(ScreenTypeDto.ScreenOne);
  const [returnToScreen, setReturnToScreen] = useState(ScreenTypeDto.ScreenOne);
  let userStorage = auth.localStorage;
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };
  const onClose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };
  const dispatch = useDispatch<any>();
  const { t } = useTranslation("common");

  useEffect(() => {
    if (product?.isLoaded) {
      if (product?.productAttributes?.length && !product?.stayAtSamePage) {
        setStep(ScreenTypeDto.ScreenOneBundle);
        setReturnToScreen(ScreenTypeDto.ScreenOneBundle);
      }
    } else {
      setStep(ScreenTypeDto.ScreenOne);
    }
  }, [brandCode, product, loadTool]);

  const onChange = (event: any) => {
    userStorage = {
      ...userStorage,
      [event.target.name]: event.target.value,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const onChangeValue = (name: any, value: any) => {
    userStorage = {
      ...userStorage,
      [name]: value,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const onChangeScreenFour = (event: any) => {
    userStorage = {
      ...userStorage,
      [event.target.name]: event.target.value,
      reLoad: false,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const onChangeValueScreenFour = (name: any, value: any) => {
    userStorage = {
      ...userStorage,
      [name]: value,
      reLoad: false,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const callScreenFive = () => {
    userStorage = {
      ...userStorage,
      chest: !userStorage.reLoad
        ? userStorage?.chest
        : cal?.data?.data?.calculatedBodyMeasurement?.chest,
      waist: !userStorage.reLoad
        ? userStorage?.waist
        : cal?.data?.data?.calculatedBodyMeasurement?.waist,
      hip: !userStorage.reLoad
        ? userStorage?.hip
        : cal?.data?.data?.calculatedBodyMeasurement?.hip,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };
  const onChangeBodyShape = (event: BodyShapeTypeDto) => {
    userStorage = {
      ...userStorage,
      bodyShape: event,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const onChangeFitPreference = (event: FitPreferenceTypeDto) => {
    userStorage = {
      ...userStorage,
      fitPreference: event,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const onChangeAttributeCode = (attributeCode: string) => {
    userStorage = {
      ...userStorage,
      attributeCode: attributeCode,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const onIsBodyMeasured = (event: boolean) => {
    userStorage = {
      ...userStorage,
      isBodyMeasured: event,
      reLoad: true,
      visitorId: _visitorId,
    };
    dispatch(AuthActions.storageUpdate(userStorage));
  };

  const getMeasurements = () => {
    userStorage = {
      ...userStorage,
      brandCode: brandCode,
      productCode: productCode,
      visitorId: _visitorId,
      userInput: true,
    };

    if (auth.localStorage.gender === GenderTypeDto.Female) {
      dispatch(AuthActions.storageFemaleUpdate(userStorage));
    } else {
      dispatch(AuthActions.storageMaleUpdate(userStorage));
    }
    dispatch(AuthActions.storageUpdate(userStorage));
    if (product?.product?.isActive) {
      dispatch(
        CalculatorActions.recommendationAsync(session.sessionId, lang, {
          ...userStorage,
          visitorId: _visitorId,
          brandCode: brandCode,
          productCode: productCode,
        } as BodyMeasurementRequestDto)
      );
    }
  };

  const onNext = (step: ScreenTypeDto) => {
    switch (step) {
      case ScreenTypeDto.ScreenTwo:
        if (
          brandCode === BrandConstants.BONITA ||
          brandCode === BrandConstants.MARCOPOLO ||
          brandCode === BrandConstants.BETTY ||
          brandCode === BrandConstants.TFS ||
          brandCode === BrandConstants.ZERO ||
          brandCode === BrandConstants.RICHROYALDE ||
          brandCode === BrandConstants.TOMTAILOR ||
          brandCode === BrandConstants.SOLIVER
        ) {
          return () => {
            onIsBodyMeasured(false);
            getMeasurements();
            setStep(ScreenTypeDto.Summary);
            saizEvents(ScreenTypeDto.Summary);
          };
        } else {
          return () => {
            onIsBodyMeasured(false);
            setStep((prev: number) => prev + 1);
            saizEvents(step + 1);
          };
        }
      case ScreenTypeDto.ScreenThree:
        return () => {
          onIsBodyMeasured(false);
          getMeasurements();
          setStep(ScreenTypeDto.Summary);
          saizEvents(ScreenTypeDto.Summary);
        };

      case ScreenTypeDto.ScreenFour:
        return () => {
          onIsBodyMeasured(true);
          getMeasurements();
          setStep(ScreenTypeDto.Summary);
          saizEvents(ScreenTypeDto.Summary);
        };

      default:
        return () => {
          onIsBodyMeasured(false);
          saizEvents(step + 1);
          setStep((prev: number) => prev + 1);
        };
    }
  };

  const prevStep = () => {
    if (
      (brandCode === BrandConstants.BONITA ||
        brandCode === BrandConstants.MARCOPOLO ||
        brandCode === BrandConstants.TOMTAILOR ||
        brandCode === BrandConstants.BETTY ||
        brandCode === BrandConstants.TFS ||
        brandCode === BrandConstants.RICHROYALDE ||
        brandCode === BrandConstants.SOLIVER ||
        brandCode === BrandConstants.ZERO) &&
      step === 4
    ) {
      setStep((prev: number) => prev - 2);
      saizEvents(step - 2);
    } else if (brandCode === BrandConstants.JACKWOLFSKIN && step === 5) {
      setStep(1);
      saizEvents(1);
    } else {
      setStep((prev: number) => prev - 1);
      saizEvents(step - 1);
    }
  };
  const getScreens = () => {
    switch (step) {
      case ScreenTypeDto.ScreenOneBundle:
        return (
          <ScreenOneBundle
            step={step}
            setStep={setStep}
            onChangeAttributeCode={onChangeAttributeCode}
          />
        );
      case ScreenTypeDto.ScreenOne:
        return (
          <ScreenOne
            data={auth?.localStorage}
            onChange={onChange}
            onChangeValue={onChangeValue}
            step={step}
            setStep={setStep}
            onEdit={() => {
              setStep(ScreenTypeDto.ScreenFour);
              callScreenFive();
              saizEvents(ScreenTypeDto.ScreenFour);
            }}
            onIsBodyMeasured={onIsBodyMeasured}
            brandCode={brandCode}
          />
        );
      case ScreenTypeDto.ScreenTwo:
        return (
          <ScreenTwo
            data={auth?.localStorage}
            onChange={onChangeBodyShape}
            step={step}
            setStep={setStep}
            brandCode={brandCode}
            onIsBodyMeasured={onIsBodyMeasured}
          />
        );

      case ScreenTypeDto.ScreenThree:
        return (
          <ScreenThree
            data={auth?.localStorage}
            setStep={setStep}
            onChange={onChangeFitPreference}
            onChangeValue={onChangeValue}
            brandCode={brandCode}
          />
        );

      case ScreenTypeDto.ScreenFour:
        return (
          <ScreenFour
            onChange={onChangeScreenFour}
            onChangeValue={onChangeValueScreenFour}
            setStep={setStep}
            brandCode={brandCode}
          />
        );

      case ScreenTypeDto.Summary:
        if (!cal.loading && !cal?.data?.noSize) {
          return (
            <ScreenSummary
              onEdit={() => {
                setStep(ScreenTypeDto.ScreenFour);
                callScreenFive();
                saizEvents(ScreenTypeDto.ScreenFour);
              }}
              shopNow={() => {
                setStep(returnToScreen);
                setModal(false);
              }}
              setReturnStep={setReturnToScreen}
              onChangeAttributeCode={onChangeAttributeCode}
              brandCode={brandCode}
              lang={lang}
              productCode={productCode}
            />
          );
        } else if (!cal.loading && cal?.data?.noSize && !product?.loading) {
          return (
            <ScreenFour
              onChange={onChange}
              onChangeValue={onChangeValue}
              setStep={setStep}
              brandCode={brandCode}
            />
          );
        }
        return <SaizLoaderScreen />;
      default:
        return <SaizLoaderScreen />;
    }
  };
  const navStep = (index: number) => {
    if (index === ScreenTypeDto.Summary) {
      getMeasurements();
    }
    if (
      (brandCode === BrandConstants.MARCOPOLO ||
        brandCode === BrandConstants.BETTY ||
        brandCode === BrandConstants.TFS ||
        brandCode === BrandConstants.TOMTAILOR ||
        brandCode === BrandConstants.RICHROYALDE ||
        brandCode === BrandConstants.SOLIVER ||
        brandCode === BrandConstants.ZERO) &&
      index === 3
    ) {
      if (!cal.loading && cal?.data && cal?.data?.displayChest) {
        setStep(4);
        saizEvents(4);
      } else {
        setStep(2);
        saizEvents(2);
      }
    } else {
      setStep(index);
      saizEvents(index);
    }
  };
  const saizEvents = (index: number) => {
    const eventProperties = {
      ...auth?.localStorage,
      brandCode: brandCode,
      productCode: productCode,
      session_id: sessionId,
      visitorId: _visitorId,
      user_id: _visitorId,
    };
    let eventName = "";
    let saizEventName = "";
    switch (index) {
      case ScreenTypeDto.ScreenOneBundle:
        eventName = "saiz_Recommender_ScreenOneBundle";
        saizEventName = "saiz_Recommender_Bundle";
        break;
      case ScreenTypeDto.ScreenOne:
        eventName = "saiz_Recommender_ScreenOne";
        saizEventName = "saiz_Recommender_UserAgeHeightWeight";
        break;
      case ScreenTypeDto.ScreenTwo:
        eventName = "saiz_Recommender_ScreenTwo";
        saizEventName = "saiz_Recommender_UserBodyShape";
        break;
      case ScreenTypeDto.ScreenThree:
        eventName = "saiz_Recommender_ScreenThree";
        saizEventName = "saiz_Recommender_UserExercise";
        break;
      case ScreenTypeDto.ScreenFour:
        eventName = "saiz_Recommender_ScreenFour";
        saizEventName = "saiz_Recommender_UserMeasurements";
        break;
      case ScreenTypeDto.Summary:
        eventName = "saiz_Recommender_ScreenSummary";
        saizEventName = "saiz_Recommender_Summary";
        break;
      default:
        saizEventName = "saiz_Recommender_ClosedScreen";
        eventName = "saiz_Reccommender_Close";
    }
    logEvent(saizEventName, "", eventProperties);
    trackWindowDataLayer(eventName, eventProperties);
  };
  return (
    <>
      <EventListners
        brandCode={brandCode}
        productCode={productCode}
        visitorId={_visitorId}
        step={step}
        sessionId={sessionId}
        lang={lang}
      />
      {localStorage.getItem("saiz-widget-cookie-flag") === "active" &&
        product?.product?.isActive && (
          <>
            <SaizLink
              brandCode={brandCode}
              getMeasurements={getMeasurements}
              setStep={setStep}
              setReturnToScreen={setReturnToScreen}
              productCode={productCode}
              setModal={setModal}
              showModal={showModal}
              onOpen={onOpen}
              isOpen={isOpen}
              saizEvents={saizEvents}
            />
            <DynamicSaizLogo brandCode={brandCode} />
            <SaizModal
              brandCode={brandCode}
              showModal={showModal}
              onClose={() => {
                setModal(false);
                setStep(returnToScreen);
                document.body.style.overflow = "unset";
                saizEvents(-1);
              }}
              isCentered={brandCode !== BrandConstants.KALYORA}
            >
              <>
                <button
                  style={{
                    display: product?.productAttributes?.length
                      ? step !== ScreenTypeDto.ScreenOneBundle
                        ? "flex"
                        : "none"
                      : step !== ScreenTypeDto.ScreenOne
                      ? "flex"
                      : "none",
                    position: "absolute",
                    alignItems: "center",
                    fontWeight: "600",
                    top: "30px",
                    left: "30px",
                    backgroundColor: "transparent",
                    color:
                      brandCode === BrandConstants.TOMTAILOR
                        ? "#001931"
                        : "black",
                  }}
                  onClick={prevStep}
                  className="saiz-modal-close-button"
                >
                  <SaizImageLoader
                    item={"back.png"}
                    alt="back"
                    style={{ marginRight: "5px" }}
                  />
                  {t("back")}
                </button>
                {getScreens()}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "0.5rem",
                  }}
                >
                  <button
                    onClick={onNext(step)}
                    style={{
                      visibility:
                        step === ScreenTypeDto.Summary ? "hidden" : "visible",
                      borderRadius:
                        brandCode === BrandConstants.TOMTAILOR ||
                        brandCode === BrandConstants.MARCOPOLO
                          ? "0px"
                          : brandCode === BrandConstants.TCHIBO
                          ? "100px"
                          : "29px",
                    }}
                    className={`saiz-main-button ${
                      step === ScreenTypeDto.Summary &&
                      "saiz-main-button-hidden"
                    }`}
                  >
                    {step === ScreenTypeDto.ScreenFour
                      ? t("getYourSize")
                      : t("next")}
                  </button>
                  <SaizNavDots
                    total={
                      brandCode === BrandConstants.MARCOPOLO ||
                      brandCode === BrandConstants.BETTY ||
                      brandCode === BrandConstants.TFS ||
                      brandCode === BrandConstants.ZERO ||
                      brandCode === BrandConstants.TOMTAILOR ||
                      brandCode === BrandConstants.SOLIVER ||
                      brandCode === BrandConstants.RICHROYALDE
                        ? 3
                        : 4
                    }
                    current={
                      (brandCode === BrandConstants.MARCOPOLO ||
                        brandCode === BrandConstants.BETTY ||
                        brandCode === BrandConstants.TFS ||
                        brandCode === BrandConstants.ZERO ||
                        brandCode === BrandConstants.SOLIVER ||
                        brandCode === BrandConstants.TOMTAILOR ||
                        brandCode === BrandConstants.RICHROYALDE) &&
                      step === 4
                        ? 2
                        : step - 1
                    }
                    navStep={navStep}
                  />
                </div>
              </>
            </SaizModal>
          </>
        )}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    product: state.ProductReducer,
    auth: state.AuthReducer,
    cal: state.CalculatorReducer,
    cookie: state.CookieReducer,
    session: state.SessionReducer,
  };
};

export default connect(mapStateToProps)(App);
